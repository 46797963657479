html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  /* font-family: inherit; */
  font-weight: inherit;
  font-style: inherit;
  vertical-align: baseline;

}
.carousel-indicators button{
  border-radius: 50%;
    /* box-shadow: 1px 1px 2px rgb(0 0 0 / 90%); */
    cursor: pointer;
    display: inline-block;
  
    margin: 0 8px !important;
    transition:.25s ease-in;
    opacity: .3;
    background: #fff;
    width: 5px !important;
  
     padding: 3px !important;
}

.carousel {
overflow: hidden;
}
.visually-hidden{
display: none !important;
}
.paragraph1{
 text-align: justify;
 font-size: 16px;
}
.goals-h5{
  margin-bottom: 0px !important;

}
.carousel-control-prev{
left: -59px !important;
}
.aboutt{
   display: flex;
   justify-content: center;
}
.img-fluidy{
  margin-bottom: 20px;
  width: 20pc;
  border: 5px solid #CE1527;
}

.carousel-control-next{
right: -59px !important;
}
.simple-trans-main > div {
cursor: pointer;
position: absolute;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
color: white;
font-weight: 800;
font-size: 5em;
will-change: transform, opacity;
text-shadow: 0px 2px 40px #00000020, 0px 2px 5px #00000030;
z-index: 2000;
}
.serviceTitle {
  padding: 5px 32px;
  background-color: #ff7b02;
  font-size: 24px;
  color: white;
  font-weight: 500;
  margin-bottom: 10px;
}
.theme_heading{
background: #ff7602;
  padding: 5px 20px;
  color: white !important;
  display: inline-block;
  border-radius: 5px;
  margin-top: 20px;
font-size: 24px !important;
}
.product_box {
  
  background-size: cover !important;
text-align: center;
padding: 40px 10px 50px 10px;
margin-bottom: 30px;
}
.product_box h3{
font-size:22px; 
margin-bottom: 15px;

}
.product_box:hover img {
  transform: translateY(5px);
}
.product_box img{
  width: 80%;
transition: 0.3s;
}
.serviceIcon {
  position: absolute;
  top: -14px;
  left: calc(50% - 36.5px);
}
.serviceIcon img{
border-radius: 4px;

}
.services_box{
position: relative;
padding: 20px;
margin-bottom: 30px;
max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}

.pickup_sec .col-md-8,
.pickup_sec .col-md-9
{
border-right: solid 1px #686868;
text-align:center;
}
.pickup_sec{
background: #272727;
}

.team_members_row{
border-bottom: solid 1px #c7c7c7;
}
.contact_info_row .contact_info_content{
  border-top: solid 1px #b5b5b5;
  border-bottom: solid 1px #b5b5b5;
  font-size: 18px;
  line-height: 1.2;
  display: inline-block;
  padding: 5px;
  margin-top: 10px;
}
.services_sec{
padding-top: 20px;
padding-bottom: 20px;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}
.services_box
.serviceContainer {
  background-color: rgb(218, 216, 216);
padding-top: 40px !important;
}

.serviceHead span {
  padding-bottom: 10px;
  border-bottom: 2px solid #ff7b02;
  font-size: 15px;
}

.serviceHead div {
  padding: 0px 28px;
  font-size: 16px;
}

.serviceHead div p {
  padding-bottom: 10px;
  border-bottom: 2px solid #ff7b02;
}
.serviceSub {
  font-size: 16px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.pickupText {
  color: white;
  font-size: 20px;
}

.bin-p{
  margin-left: 30px !important;
}

.btn-pickup {
  /* background-color: #ff7602 !important; */
  color: white !important;
  font-weight: 500 !important;
  width: 188px !important;
background-color: rgb(255, 118, 2) !important;
}
.auto-image{
width: 300px;
}
.autop{
  margin-left: 60px;
}

section.our-team.our_team_sec {
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.our_team_sec .container-fluid{
max-width: 92%;
}

.client-logo {
  max-width: 100%;
  border-radius: 10px !important;
}
.about_company_content  h3{
font-weight: bold;
font-size: 40px;
margin-bottom: 0;
}
.about_company_content  h5{
margin-bottom: 30px;
font-size: 32px;
font-weight: 200;
}
.about_company_content .container{
padding-bottom: 50px;
border-bottom: solid 1px #c7c7c7;
position: relative;
}

.about_company_content .container .logo_footer{

  position: absolute;
  bottom: -50px;
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;

}

/*---------------------------------------
**   BORDER BOTTOMS                 -----
-----------------------------------------*/

.white-border-bottom:before {
position: absolute;
z-index: 1;
bottom: -9px;
left: 25%;
width: 50%;
height: 2px;
margin: auto;
background: #f5f5f5;
content: "";
}
.dark-border-bottom:before {
position: absolute;
z-index: 1;
bottom: -9px;
left: 25%;
width: 50%;
height: 2px;
margin: auto;
background: #404040;
content: "";
}
.red-border-bottom:before {
position: absolute;
z-index: 1;
bottom: -9px;
left: 12.5%;
width: 75%;
height: 2px;
margin: auto;
background: #e96656;
content: "";
}
.green-border-bottom:before {
position: absolute;
z-index: 1;
bottom: -9px;
left: 12.5%;
width: 75%;
height: 2px;
margin: auto;
background: #34d293;
content: "";
}
.blue-border-bottom:before {
position: absolute;
z-index: 1;
bottom: -9px;
left: 12.5%;
width: 75%;
height: 2px;
margin: auto;
background: #3ab0e2;
content: "";
}
.yellow-border-bottom:before {
position: absolute;
z-index: 1;
bottom: -9px;
left: 12.5%;
width: 75%;
height: 2px;
margin: auto;
background: #f7d861;
content: "";
}

/*---------------------------------------
**   Section: Our team;              -----
-----------------------------------------*/
.our-team .col-lg-3:nth-child(4n+1) {
clear: both;
}
.team_members_row .col-lg-3{

flex: 0 0 20%;
  max-width: 20%;
}

.team_members_row .col-lg-3:nth-child(6),
.team_members_row .col-lg-3:nth-child(7),
.team_members_row .col-lg-3:nth-child(8),
.team_members_row .col-lg-3:nth-child(9){

flex: 0 0 25%;
  max-width: 25%;
}

.our-team {
/* padding-top: 100px; */
padding-bottom: 66px;
background: #fff;
}
.team-box {
display: inline-block;
float: none !important;
margin-right: -4px;
margin-bottom: 25px;
vertical-align: top;
}
.team-member {
overflow: hidden;
position: relative;
margin-bottom: 35px;
border-radius: 4px;
}
.team-member .details {
position: absolute;
top: -200px;
left: 0;
width: 100%;
height: 190px;
padding: 15px;
opacity: 0;
font-size: 13px;
line-height: 20px;
text-align: left;
-webkit-transition: all 500ms;
transition: all 500ms;

-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
filter: alpha(opacity=0);
}
.team-member .member-details {
display: inline-block;
position: relative;
padding-bottom: 5px;
}
.team-member:hover .details,
.team-member:focus .details {
top: 0;
opacity: 1;
color: white;
background: #333;

-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
filter: alpha(opacity=100);
}
.team-member.team-member-open .details {
display: block;
top: 0;
opacity: 1;
color: white;
background: #333;
-webkit-transition: none;
-moz-transition: none;
-o-transition: none;
transition: none;

-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
filter: alpha(opacity=100);
}
.team-member .profile-pic {
overflow: hidden;
width: 174px;
height: 174px;
margin: auto;
margin-bottom: 25px;
border-radius: 50%;
}
.team-member .profile-pic img {
width: 100%;
height: 100%;
}
.team-member h3 {
position: relative;
margin-top: 15px;
color: #404040;
font-size: 17px;
font-weight: 700;
text-transform: uppercase;
}
.team-member .position {
font-size: 13px;
margin-top: 10px;
}
.team-member .social-icons {
margin-bottom: 25px;
}
.team-member .social-icons ul {
margin: 0 0 1.5em 0;
}
.team-member .social-icons ul li {
display: inline-block;
margin: 6px;
line-height: 32px;
}
.team-member .social-icons ul li a {
border-radius: 50%;
color: #808080;
background: #fff;
font-size: 18px;
}
.team-member .social-icons ul li a:hover {
color: #e96656;
}
.our-team .row > div:nth-child(4n+1) .red-border-bottom:before {
background: #e96656;
}
.our-team .row > div:nth-child(4n+2) .red-border-bottom:before {
background: #34d293;
}
.our-team .row > div:nth-child(4n+3) .red-border-bottom:before {
background: #3ab0e2;
}
.our-team .row > div:nth-child(4n+4) .red-border-bottom:before {
background: #f7d861;
}

.section-header {
  text-align: center;
}

.section-header h2 {
  color: #ff7b02;
}

.products_cards {
  background: #efefef;
  padding-bottom: 30px;
  margin-bottom: 20px;
}



/*---------------------------------------
   **   Section: About us;              -----
  -----------------------------------------*/

  .About-us {
    margin-left: 55px !important;

  }
  .about-us .big-intro {
      
      font-size: 60px;
      font-weight: 300;
      line-height: normal;
      text-align: right;
  }

  .about-us .big-intro > img {
      max-width: 100%;
      height: auto;
  }
  .about-us .section-header .section-legend {
      color: #fff;
  }
  .about-us h6 {
      color: white;
      line-height: 25px;
      text-align: left;
      font-size: 16px;
      font-weight: 400;
  }
  .about-us .column {
      margin-bottom: 78px;
  }
  .about-us .col-md-12.zerif-rtl-big-title .big-intro,
  .about-us .col-md-12.zerif_about_us_center p {
      text-align: center;
  }

  .president-msg h2 {
      font-size: 28px
  }
  .president-msg h3 {
      font-size: 24px
  }
  .president-msg h2 span {
      font-size: 18px;
      color: red;
  }

  /* Pages */
#content {
  min-height: 380px;
}
.product_detail_pg{
  background: #f4f4f4;
  padding-top: 40px;
  padding-bottom: 40px;
}

.product-category-page h1.page-title {
  position: relative;
  color: #404040;
  font-family: "Montserrat", Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  margin-bottom: 20px;
}
.product-category-page h1.page-title1 {
  position: relative;
  color: #404040;
  font-family: "Montserrat", Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  margin-bottom: 20px;
}
.product-category-page h1.page-title2 {
  position: relative;
  color: #404040;
  font-family: "Montserrat", Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  margin-bottom: 20px;
}
.product-category-page h1.page-title3 {
  position: relative;
  color: #404040;
  font-family: "Montserrat", Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  margin-bottom: 20px;
}
.product-category-page h1.page-title4 {
  position: relative;
  color: #404040;
  font-family: "Montserrat", Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  margin-bottom: 20px;
}
.page-title1{
  text-align: center !important;
}
.page-title2{
  text-align: center !important;
}
.page-title{
  text-align: center !important;
}
.page-title3{
  text-align: center !important;
}
.page-title4{
  text-align: center !important;
}
.product-category-page .page-title1:before {
  position: absolute;
  z-index: 1;
  bottom: -9px;

  width: 15%;
  height: 2px;
  margin: auto;
  background: #e96656;
  content: "";
}
.product-category-page .page-title4:before {
  position: absolute;
  z-index: 1;
  bottom: -9px;

  width: 25%;
  height: 2px;
  margin: auto;
  background: #e96656;
  content: "";
}
.product-category-page .page-title3:before {
  position: absolute;
  z-index: 1;
  bottom: -9px;

  width: 14%;
  height: 2px;
  margin: auto;
  background: #e96656;
  content: "";
}
.product-category-page .page-title2:before {
  position: absolute;
  z-index: 1;
  bottom: -9px;

  width: 20%;
  height: 2px;
  margin: auto;
  background: #e96656;
  content: "";
}

.product-category-page .page-title:before {
  position: absolute;
  z-index: 1;
  bottom: -9px;

  width: 22%;
  height: 2px;
  margin: auto;
  background: #e96656;
  content: "";
}

.product-category-page .page-title.text-center:before{
  left: calc(50% - 5%);
  
}
  .About-us{
  text-align: justify;
}
.our-client .cal{
  border: 1px solid #a0a0a0; 
  border-radius: 7px;
}
.H3{
  color: black !important;
  
}

.membership{
  text-align: center;
}



.Client{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.product-category-page .product-title {
  display: block;
  float: none;
  position: relative;
  width: auto;
  min-height: 42px;
  margin: 0;
  color: #404040;
  font-size: 16px !important;
  font-weight: bold;
  line-height: 14px;
  text-transform: uppercase;
}

.category-link {
  color: #e96656;
}

.related-product {
  padding-top: 100px;
}

.related-product h2 {
  display: inline-block;
  float: none;
  position: relative;
  width: auto;
  min-height: 42px;
  margin: 0;
  color: #404040;
  font-size: 16px !important;
  font-weight: bold;
  line-height: 14px;
  text-transform: uppercase;
}

/* Contact Us */
#contact {
  background-color: #fbfafa;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.about-cert-img-border {
  border: 4px solid #ce1527
}

  
@media (max-width: 500px) {
  .our-client .col-md-2 {
      margin-top: 10px;
  }
  
} 

@media (min-width: 768px) {
  .service-page, .product-category-page {
  padding-top: 0PX;
}
  
}  

@media (max-width: 768px) {
  .our-client .col-md-2 {
      margin-top: 10px;
  }
  .team_members_row .col-lg-3{

  flex: 0 0 50% !important;
  max-width: 50% !important;
}
}    



@media (min-width: 768px) and (max-width: 1024px) {
  /* ABOUT US */
  .big-intro {
      text-align: center !important;
  }
  .about-us .column {
      margin-bottom: 40px;
  }
  .product {
      display: flex;
      flex-direction: column;
      /* justify-content: space-between; */
  }

  /* .our-client .client-logo {
      width: 150px;
  } */
  
}

@media (min-width: 992px) {
  .serviceContainer {
      height: 340px;
  }
  .section-header h2 {
  font-size: 35px;
}
.section-header {
  padding-bottom: 30px;
}
  .about-us .big-intro {
  text-align: center;
}

  .product {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
  }

  .our-client .client-logo {
      width: 105px;
  }
}