.product-detail-tab {
    justify-content: center;
    list-style: none;
    padding: 0 0 0 1em;
    margin: 0 0 1.618em;
    position: relative;
    border-bottom: 1px solid #ccc;
}

.product-detail-tab::before {
    position: absolute;
    content: ' ';
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #d3ced2;
    z-index: 1;
    display: table;
    box-sizing: border-box;
}

.product-detail-tab li {
    display: inline-block;
    box-shadow: none;
    padding-bottom: 0;
    border: none;
    position: relative;
    border-radius: 4px 4px 0 0;
    margin: 0 -5px;
    padding: 0 1em;
    list-style: none;
}

.product-detail-tab li.active {
    margin-bottom: -1px;
    border-bottom: 2px solid #e96656;
    background: #fff;
    z-index: 2;
}

.product-detail-tab li a {
    display: inline-block;
    padding: .5em 0;
    font-weight: 700;
    text-decoration: none;
    font-size: 18px;
    transition: all 700ms;
    color: #515151;
}

.product-detail-tab li.active a {
    color: inherit;
    text-shadow: inherit;
}

.entry-content::before {
    display: table;
    content: "";
}

.entry-content h2 {
    font-size: 26px;
    font-family: "Montserrat", Helvetica, sans-serif;
    font-weight: 700;
    line-height: 35px;
}

.entry-content p {
    text-align: justify;
    margin: 0 0 24px;
}