/*---------------------------------------
 **   Footer           -----
-----------------------------------------*/

#footer {
	background: #272727;
	line-height: 19px;
    color: #939393;
}
.company-details {
	padding-bottom: 30px;
	color: #939393;
}
.container .company-details {
    width: 33%;
    padding-top: 40px;
}
.company-details a {
	color: #939393;
	text-decoration: none;
}



.company-details a:hover {
	color: #e96656;
}
.company-details .icon-top {
	margin-bottom: 10px;
	font-size: 30px;
}
.copyright {
	padding-top: 68px;
	padding-bottom: 68px;
	background: #939393;
}

.copyright p {
	margin-bottom: 0;
	color: #272727;
}


.company-details {
    padding-top: 0;
    padding-bottom: 33px;
}
.copyright {
    width: 100%;
    padding-top: 33px;
    padding-bottom: 33px;
	color: #272727;
}
footer.entry-footer {
    padding-top: 0;
}
