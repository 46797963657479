#main-nav {
    min-height: 76px;
    z-index: 1000;
    width: 100%;
    margin-bottom: 0;
    top: 0;
    padding: .2rem 1rem;
    background: transparent !important;
   position: relative !important;
}



nav.navbar {
    width: 100%;
  
}

.navbar-brand {
    padding: 0 !important;
    width: 120px;
    background-color: transparent !important;
}

.navbar-brand img{
    width: 100%;
    
    
}
.navbar .navbar-nav .nav-link {
    font-weight: 900;
    color: black !important;
}


/* .navbar-nav {
    position: absolute;
    right: 0;
} */

.navbar-nav > li > a:hover {
    color: #e96656 !important;
}



.carousel .thumbs-wrapper {
    display: none;
}

@media (min-width: 992px) {
    #main-nav {
        position: absolute;
    }
   
    .navbar-brand {
        display: inline-block;
        position: relative;        
        padding: 7px 15px;
        line-height: 60px;
        text-align: center;
    }

    .navbar-expand-lg .navbar-nav {
        width: 100%;
        justify-content: space-evenly;
    }
    
}

@media (max-width: 767px) {
    .navbar-brand {
        display: inline-block;
        position: relative;
        height: 76px;
        padding: 7px 15px;
        line-height: 60px;
        text-align: center;
    }
    .navbar-brand img {
        width: 60px;
        height: 57px;
    }
    div#navbarSupportedContent {
        height: 320px;
    }
    .navbar-nav {
        position: absolute;
        right: unset;
        width: 95%;
    }
    .navbar-nav li.nav-item {
        border-bottom: 1px solid #e4e4e4;
    }
}

